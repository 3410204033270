<template>
  <win-block>
    <template #win-title-left>Экспорт</template>


    <v-row no-gutters justify="center" align="center">

      <v-col  cols="3"/>

      <v-col  cols="3">

        <v-file-input
            show-size
            label="File input"
            @change="selectFile"
        />
      </v-col>
      <v-col  cols="3">
        <v-btn color="primary" dark small @click="upload" :loading="wait_upload">
          Загрузить
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
      <v-col  cols="3"/>
    </v-row>
    <v-alert
        v-if="result_detail"
        prominent
        type="info"
    >
      <v-row align="center">
        <v-col class="grow">
          <div v-html="result_detail"/>
        </v-col>
      </v-row>
    </v-alert>
  </win-block>
</template>

<script>

export default {
  components: {
    "win-block": () => import('@/site/componets/winblock'),
  },
  data: () => ({
    wait_upload: false,
    result_detail: '',
    currentFile: undefined,
  }),
  methods: {
    selectFile(file) {
      this.progress = 0;
      this.currentFile = file;
    },
    upload() {
      if (!this.currentFile) {
        this.message = "Please select a file!";
        return;
      }
      this.message = "";
      this.sendfile(this.currentFile)
    },
    sendfile(file) {
      // console.log('!!!!!!!',file)
      let formData = new FormData();
      formData.append("file", file);
      this.$axios.post(this.$url.server_project_upload, formData, {
        headers: {"Content-Type": "multipart/form-data"},
        zLoader: [this, 'wait_upload']
      })
          .then((response) => {
            this.result_detail = response.data.alert.join('<br/>')
            this.$bus.emit('snackbar', {
              "title": this.$t("Файл обработан"),
              "text": this.convertToText(response.data),
              "timeout": 10000
            });
            this.currentFile = undefined;
          })
          .catch(() => {
            this.$bus.emit('snackbar', {"text": this.$t("Ошибка загрузки файла")});
            this.currentFile = undefined;
          });
    },
    convertToText(obj) {
      let update_active = `Активация - ${obj.update_active}`
      let owners_add = `Новых - ${obj.owners_add}`
      let owners_source = `Всего обработано - ${obj.owners_source}`
      let owners_updated = `Обновленных - ${obj.owners_updated}`
      let error = obj.error ? obj.error + '<br/>' : ''
      return `${error}${owners_source}<br/>${owners_add}<br/>${update_active}<br/>${owners_updated}<br/>`
    }
  }
}
</script>


<style lang="scss">
.z-spin {
  animation: spin-animation 1.5s infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-359deg);
  }
}
</style>